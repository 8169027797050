import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../../components/nav/Nav";
import styles from "./contact.css";
import baseStyles from "../../styles/base.css";
import privacyPolicy from "../../assets/privacy-policy.pdf";

const Contact = () => (
  <div className={`${styles.container} ${baseStyles.container}`}>
    <Helmet>
      <title>Nico Furniture | Contact</title>
    </Helmet>
    <Nav />
    <div className={styles.textContainer}>
      <p>All furniture is handmade to commission in any size and finish.</p>
      <p>Enquiries to Nico Villeneuve.</p>
      <p>+44 (0)7931 547474</p>
      <p>
        <a href="mailto:me@nicofurniture.com">me@nicofurniture.com</a>
      </p>
      <p>162 The Close, Salisbury, Wiltshire SP1 2EZ, United Kingdom</p>
      <p>
        <a href={privacyPolicy} target="_blank">
          Privacy policy
        </a>
      </p>
    </div>
  </div>
);

export default Contact;
