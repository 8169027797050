import React from "react";
import Nav from "../../components/nav/Nav";
import FurnitureImage from "../../components/furniture-image/FurnitureImage";
import styles from "./home.css";
import baseStyles from "../../styles/base.css";

const Home = () => (
  <div className={`${styles.container} ${baseStyles.container}`}>
    <Nav />
    <div className={styles.imgHolder}>
      <FurnitureImage className={styles.img} reference="169" alt="169" />
    </div>
    <p className={styles.tagline}>
      Bespoke furniture
      <br />
      by <strong className={styles.nico}>Nico Villeneuve</strong>
    </p>
  </div>
);

export default Home;
